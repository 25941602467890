/* eslint-disable no-undef */

export const env = getEnvConfig();

export const theme = getThemeConfig();

export const functions = {
  autoCal: {},
  validate: {},
  calculate: {},
};

const configs = {
  env,
  theme,
  functions,
};

export default configs;
