import { compose, withHooks, withTranslation } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Modal, ExcelGenerator } from "components";
import { gql, notifySuccess, paths, formatDate, homePath } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as RedTrashIcon } from "assets/icon/red_trash.svg";
import { isEmpty } from "lodash";
import { BreadcrumbsProps } from "layouts/PageContent";
import { ROLES } from "constants/enums/role";
import { GridColDef } from "@material-ui/data-grid";

const RequestIndexPage = (props: any) => (
  <PageContent
    title={props.t(".title")}
    breadcrumbs={props.breadcrumbs}
    pageActions={[]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        {props.t(".title")}
      </Typography>
      {/* <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.adminEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
      /> */}
    </Box>
  </PageContent>
);

export const API = {};

const enhancer = compose(
  withTranslation({
    prefix: "pages.main.request.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { t } = props;
    const { useMemo } = hooks;

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    return {
      breadcrumbs,
    };
  })
);

export default enhancer(RequestIndexPage);
