import { compose, withHooks } from "enhancers";
import { Box, Typography, Button } from "components";
import { forceDownload } from "utils/helper";
import { AppColor } from "theme/app-color";

interface FileUploadProps {
  download: () => void;
  fileName: string;
  simple: boolean;
  loadFile: () => void;
}

const FileUpload = (props: FileUploadProps) => (
  <Box display="flex" alignItems="center" {...props}>
    <Typography
      variant="body1"
      flex={1}
      noWrap
      onClick={props.download}
      style={{ textDecoration: "underline", color: AppColor["Other/Info"] }}
    >
      {props.fileName}
    </Typography>
    {!props.simple && (
      <Button startIcon="download" onClick={props.download} ml={6} />
    )}
  </Box>
);

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useCallback, useEffect } = hooks;
    const { field, form, simple, ...rest } = props;

    const file = field.value;
    const fileName = file?.name || file?.filename || file?.fileName || "";
    const download = useCallback(async () => {
      if (file.path && file.name && file.size && file.type) {
        const url = URL.createObjectURL(file);
        forceDownload(url, fileName);
      } else {
        const { url, filename } = file;
        const blob = await fetch(url).then((r) => r.blob());
        const data = new File([blob], filename, { type: blob.type });
        const urlObject = URL.createObjectURL(data);
        forceDownload(urlObject, fileName);
      }
    }, [file, fileName]);

    useEffect(() => {
      if (file) {
        const { url, filename } = file;
        if (filename && url) {
          const loadFile = async () => {
            const blob = await fetch(url).then((r) => r.blob());
            const file = new File([blob], filename, { type: blob.type });
            form.setFieldValue(field.name, file);
          };
          loadFile();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    return {
      ...rest,
      fileName,
      download,
      simple,
    };
  })
);

export default enhancer(FileUpload);
