import {
  compose,
  withHooks,
  withFormik,
  withStores,
  withTranslation,
} from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  ErrorMessage,
  Notification,
  Select,
  SwitchInput,
  Alert,
} from "components";
import {
  getErrorMessage,
  gql,
  homePath,
  notifyError,
  paths,
} from "utils/helper";
import { isEqual } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useCallback } from "react";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { BreadcrumbsProps } from "layouts/PageContent";

const AdminEditPage = (props: any) => (
  <PageContent title={props.code} breadcrumbs={props.breadcrumbs}>
    <Form>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">{props?.t(".title")}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Field
              component={SwitchInput}
              name="availableStatus"
              label={props.t(".available")}
              disabled={props.isEditYourself || !props.hasEditPermission}
            />
          </Box>
        </Box>
        <ErrorMessage with="100%" name="__error__" mt={6} />
        {props.isEditYourself && (
          <Alert severity="info" mt={6}>
            <Typography variant="body1">
              {props.t(".cantEditYourself")}
            </Typography>
          </Alert>
        )}
        <Box mt={6}>
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" flex={1}>
              <Field
                component={TextField}
                name="firstName"
                type="text"
                label={props.t(".firstName")}
                fullWidth
                mb={6}
                disabled={!props.hasEditPermission}
                required
              />
              <Field
                component={TextField}
                name="email"
                type="text"
                label={props.t(".email")}
                disabled={true}
                fullWidth
                required
              />
            </Box>

            <Box display="flex" flex={1} mx={6}>
              <Field
                component={TextField}
                name="lastName"
                type="text"
                label={props.t(".lastName")}
                fullWidth
                required
                disabled={!props.hasEditPermission}
              />
            </Box>

            <Box display="flex" flex={1}>
              <Field
                component={Select}
                name="roleId"
                type="text"
                label={props.t(".role")}
                options={props.roleOptions}
                fullWidth
                disabled={props.isEditYourself || !props.hasEditPermission}
              />
            </Box>
          </Box>

          <Box display="flex" mt={10}>
            {props.hasEditPermission && (
              <>
                <Button
                  width={74}
                  p={0}
                  mr={2}
                  onClick={props.onBack}
                  variant="outlined"
                >
                  {props.t(".cancel")}
                </Button>

                <Button type="submit" width={74} p={0} variant="contained">
                  {props.t(".save")}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  FETCH_ROLES: gql`
    query FETCH_ROLES {
      roles {
        id
        title
      }
    }
  `,
  FETCH_ADMIN: gql`
    query FETCH_ADMIN($id: String!) {
      admin(id: $id) {
        id
        code
        firstName
        lastName
        backofficeUser {
          email
          roleId
          availableStatus
          role {
            id
            title
            permissions
          }
        }
        roleOptions
      }
    }
  `,
  UPDATE_ADMIN: gql`
    mutation UPDATE_ADMIN(
      $id: String!
      $firstName: String
      $lastName: String
      $roleId: String
      $availableStatus: Boolean
    ) {
      editAdminInfo(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          roleId: $roleId
          availableStatus: $availableStatus
        }
      ) {
        admin {
          id
          code
          firstName
          lastName
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withFormik({}),
  withTranslation({ prefix: "pages.main.admins.edit" }),
  withPreventLeaveDirtyForm(),
  withHooks((props: any, hooks: any) => {
    const { t, values, currentUser, setInitialValues } = props;
    const {
      useQuery,
      useMutation,
      useMemo,
      useHandleSubmit,
      useEffect,
      useParams,
    } = hooks;
    const { id } = useParams();

    const isEditYourself = useMemo(() => currentUser?.admin?.id === id, [
      currentUser,
      id,
    ]);

    const haveAdminManagePerm = useMemo(() => {
      const havePerm = currentUser?.role?.permissions.some((perm: any) => {
        if (perm === "ADMIN_MANAGEMENT_EDIT") {
          return true;
        }
        return false;
      });
      return havePerm;
    }, [currentUser]);

    const hasEditPermission = ["ADMIN_MANAGEMENT_EDIT"].every((perm) =>
      currentUser?.role?.permissions.includes(perm)
    );

    const { loading, data, error, refetch } = useQuery(API.FETCH_ADMIN, {
      variables: { id },
    });
    const [updateAdmin] = useMutation(API.UPDATE_ADMIN);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const roleOptions = useMemo(() => {
      return data?.admin.roleOptions || [];
    }, [data]);

    const admin = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.adminsPath().push();
        return null;
      }
      return data.admin;
    }, [loading, data, error]);

    useEffect(() => {
      if (admin) {
        setInitialValues({
          ...admin,
          ...admin.backofficeUser,
        });
      }
    }, [setInitialValues, admin]);

    useHandleSubmit(
      async (values: any) => {
        await updateAdmin({
          variables: values,
        });
        paths.adminsPath().push();
        Notification.success("แก้ไขข้อมูลสำเร็จ");
      },
      [setInitialValues, updateAdmin]
    );

    const disabledSubmitButton = useMemo(() => isEqual(values, data?.admin), [
      values,
      data,
    ]);

    const statusOptions = useMemo(
      () => [
        { label: "ใช้งาน", value: "true" },
        { label: "ไม่ใช้งาน", value: "false" },
      ],
      []
    );

    const onBack = useCallback(() => {
      paths.adminsPath().push();
    }, []);

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { path: paths.adminsPath(), label: t(".rootPath") },
        { label: admin?.code, path: null },
      ],
      [admin?.code, t]
    );

    return {
      code: admin?.code,
      disabledSubmitButton,
      roleOptions,
      statusOptions,
      isEditYourself,
      haveAdminManagePerm,
      onBack,
      hasEditPermission,
      breadcrumbs,
    };
  })
);

export default enhancer(AdminEditPage);
