import Notification from "components/common/Notification";
import { default as orgOmitDeep } from "omit-deep";
import { cloneDeep, get, isString, set } from "lodash";
import { format, getYear, isEqual, isValid, parseISO } from "date-fns";
import appStore from "stores/appStore";
import { MAIN_MENU } from "routes/menu";
import paths from "routes/paths";
import { Modal } from "components";
import { t } from "i18next";
import { sprintf } from "sprintf-js";
export { default as deepEqual } from "deep-equal";
export { gql } from "@apollo/client";
export * as Yup from "yup";
export { default as paths } from "routes/paths";
export { sprintf } from "sprintf-js";

export const toDate = (date) => {
  if (isString(date)) {
    date = parseISO(date);
  }
  return date;
};

export const formatDate = (date, pattern) => {
  if (isString(date)) {
    date = parseISO(date);
  }

  if (date) {
    pattern = pattern.replace("be(yyyy)", `${getYear(date) + 543}`);
    return format(date, pattern);
  } else {
    return null;
  }
};

export const deepTransformStringToDate = (object) => {
  if (object instanceof ArrayBuffer) {
    return object;
  }
  if (object instanceof Array) {
    return object.map((obj) => deepTransformStringToDate(obj));
  }
  if (object instanceof Object) {
    return Object.entries(object).reduce(
      (memo, [key, value]) => ({
        ...memo,
        [key]: deepTransformStringToDate(value),
      }),
      {}
    );
  }
  if (typeof object === "string") {
    if (/^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?/.test(object)) {
      return new Date(object);
    }
  }
  return object;
};

export const getFullAddress = (info) => {
  const isBangkok = info.province === "กรุงเทพมหานคร";
  const subDistrictPrefix = isBangkok ? "แขวง" : "ตำบล";
  const districtPrefix = isBangkok ? "เขต" : "อำเภอ";
  return `${info.address ?? ""} ${
    info.subDistrict ? `${subDistrictPrefix} ${info.subDistrict}` : ""
  } ${info.district ? `${districtPrefix} ${info.district}` : ""} ${
    info.province ?? ""
  } ${info.zipCode ?? ""}`
    .replace(/ +/g, " ")
    .replace(/^ *$/, "");
};

// prettier-ignore
const CARBON_FOOTPRINT_TABLE = {
  knit: {
    polyester_100: { white:  5.44, light:  8.96, medium: 11.21, dark: 15.22, very_dark: 19.33 },
    tc:            { white: 11.56, light: 15.21, medium: 17.25, dark: 20.89, very_dark: 24.61 },
    cvc:           { white: 11.69, light: 15.36, medium: 17.20, dark: 20.46, very_dark: 23.81 },
    cotton_100:    { white: 12.62, light: 16.39, medium: 18.05, dark: 21.00, very_dark: 24.02 },
  },
  woven: {
    polyester_100: { white:  9.41, light: 13.01, medium: 15.26, dark: 19.27, very_dark: 23.37 },
    tc:            { white: 15.91, light: 19.77, medium: 21.82, dark: 25.45, very_dark: 29.18 },
    cvc:           { white: 17.17, light: 21.25, medium: 23.09, dark: 26.35, very_dark: 29.70 },
    cotton_100:    { white: 18.24, light: 22.58, medium: 24.23, dark: 27.18, very_dark: 30.20 },
  },
};

// prettier-ignore
const WATER_FOOTPRINT_TABLE = {
  knit: {
    polyester_100: { white: null, light: null, medium: null, dark: null, very_dark: null },
    tc:            { white: null, light: null, medium: null, dark: null, very_dark: null },
    cvc:           { white: null, light: null, medium: null, dark: null, very_dark: null },
    cotton_100:    { white: null, light: null, medium: null, dark: null, very_dark: null },
  },
  woven: {
    polyester_100: { white: null, light: null, medium: null, dark: null, very_dark: null },
    tc:            { white: null, light: null, medium: null, dark: null, very_dark: null },
    cvc:           { white: null, light: null, medium: null, dark: null, very_dark: null },
    cotton_100:    { white: null, light: null, medium: null, dark: null, very_dark: null },
  },
};

export function getCarbonFootprintPerKg(category, tone, carbonFootprintType) {
  const type = {
    knit_natural: "knit",
    knit_synthetic: "knit",
    knit_mixed: "knit",
    woven_natural: "woven",
    woven_synthetic: "woven",
    woven_mixed: "woven",
    non_woven: "knit",
  }[category];
  return get(CARBON_FOOTPRINT_TABLE, `${type}.${carbonFootprintType}.${tone}`);
}

export function getWaterFootprintPerKg(category, tone, carbonFootprintType) {
  const type = {
    knit_natural: "knit",
    knit_synthetic: "knit",
    knit_mixed: "knit",
    woven_natural: "woven",
    woven_synthetic: "woven",
    woven_mixed: "woven",
    non_woven: "knit",
  }[category];
  return get(WATER_FOOTPRINT_TABLE, `${type}.${carbonFootprintType}.${tone}`);
}

export function calculateKgToYard(gsm, fw, kg = 1) {
  const kgPerSyards = (gsm * fw) / 43.06 / 1000;
  const yardPerKg = 1 / kgPerSyards;
  return kg * yardPerKg;
}

export function toCurrency(number, options = {}) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...options,
  }).format(number);
}

export function omitDeep(object, key) {
  return orgOmitDeep(cloneDeep(object), key);
}

export function cleanTypename(object) {
  return omitDeep(object, "__typename");
}

export function forceDownload(href, filename) {
  let anchor = document.createElement("a");
  anchor.href = href;
  anchor.download = filename ?? href;
  document.body.appendChild(anchor);
  anchor.click();
}

export function notify(message) {
  Notification.notify(message);
}

export function notifySuccess(message) {
  Notification.success(message);
}

export function notifyError(error) {
  if (isString(error)) {
    Notification.error(error);
  } else {
    let errorMessage = null;
    try {
      errorMessage = error?.networkError?.result?.errors
        ?.map((e) => e.message)
        ?.join(", ");
    } catch (e) {}
    errorMessage =
      errorMessage ||
      error?.networkError?.result?.message ||
      "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน";
    Notification.error(errorMessage);
  }
}

export function getErrorMessage(error) {
  if (isString(error)) {
    return error;
  } else {
    let errorMessage = null;
    try {
      errorMessage = error?.networkError?.result?.errors
        ?.map((e) => e.message)
        ?.join(", ");
    } catch (e) {}
    errorMessage =
      errorMessage ||
      error?.networkError?.result?.message ||
      "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน";
    return errorMessage;
  }
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const showSplashScreen = (isShow = true) => {
  const $splashScreen = window.document.getElementById("splash-screen");
  const $appScreen = window.document.getElementById("root");
  if ($appScreen) {
    $appScreen.style.display = isShow ? "none" : "block";
  }
  if ($splashScreen) {
    $splashScreen.style.display = isShow ? "flex" : "none";
  }
};

export const redirect = (pathname) => {
  if (window.location.pathname !== pathname) {
    window.location.href = pathname;
  }
};

export const formatPhoneNumber = (str) => {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "" + match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
};

export const formatFullName = (firstName, lastName) => {
  return `${firstName || ""} ${lastName || ""}`.replace(/^ $/, "");
};

export const formatBankNumber = (str) => {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{1})(\d{5})(\d*)$/);
  if (match) {
    return "" + match[1] + "-" + match[2] + "-" + match[3] + "-" + match[4];
  }
  return null;
};

export const formatTaxId = (str) => {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})$/);
  if (match) {
    return (
      "" +
      match[1] +
      "-" +
      match[2] +
      "-" +
      match[3] +
      "-" +
      match[4] +
      "-" +
      match[5]
    );
  }
  return null;
};

const ranges = [
  { divider: 1e18, suffix: "E" },
  { divider: 1e15, suffix: "P" },
  { divider: 1e12, suffix: "T" },
  { divider: 1e9, suffix: "G" },
  { divider: 1e6, suffix: "M" },
  { divider: 1e3, suffix: "k" },
];

export const formatNumber = (n) => {
  let isNegativeNumber = false;
  if (n < 0) {
    isNegativeNumber = true;
    n = Math.abs(n);
  }
  for (let i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      if (isNegativeNumber) {
        return (
          "-" + (n / ranges[i].divider).toFixed(2).toString() + ranges[i].suffix
        );
      } else {
        return (n / ranges[i].divider).toFixed(2).toString() + ranges[i].suffix;
      }
    }
  }

  return n.toString();
};

export const replaceNameSpace = (initPrefix, ns) => {
  return ns.replaceAll(/(^\.|\$t\(\.)(?=\w)/g, (match) => {
    if (match.length && match.length === 1) {
      return "client." + initPrefix + ".";
    } else {
      const withOutLastChar = match.substring(0, match.length - 1);
      return withOutLastChar + "client." + initPrefix + ".";
    }
  });
};

export function homePath(permissions) {
  for (const menu of MAIN_MENU) {
    if (
      menu.permissions?.some((permission) =>
        permissions
          ? permissions.includes(permission)
          : appStore.state.currentUser.role.permissions.includes(permission)
      )
    ) {
      return menu.path;
    }
  }
  return paths.adminsPath();
}

export function publishedAlert(disablePreventLeaveDirtyForm) {
  Modal.alert({
    title: t("client.error.saveError"),
    children: t("client.error.saveErrorChildren"),
    okButtonLabel: t("client.error.accept"),
    onOk: async ({ close }) => {
      if (disablePreventLeaveDirtyForm) await disablePreventLeaveDirtyForm();
      close();
      window.location.reload();
    },
    disableBackdropClick: true,
  });
}
export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
// from DateSelector
export const adjustDayValue = (day, lastDay) => {
  const adjustDay = day > lastDay ? lastDay : day;
  return adjustDay !== day ? adjustDay : null;
};

export const adjustDateValue = (year, month, day) => {
  let adjustDate = parseISO(
    sprintf("%d-%02d-%02d", year ?? 0, month ?? 0, day ?? 0)
  );
  return isValid(adjustDate) ? adjustDate : null;
};

export const setAdjustedValues = (
  setFieldValue,
  outerSetFieldValue,
  outerFieldName,
  day,
  adjustDay,
  outerFieldValue,
  adjustDate
) => {
  if (adjustDay !== null) {
    setFieldValue("day", adjustDay);
  }

  if (!isEqual(outerFieldValue, adjustDate)) {
    outerSetFieldValue(outerFieldName, adjustDate);
  }
};
//
// withFormik.bak
export const validateValues = async (values, config) => {
  if (config.validationSchema) {
    await config.validationSchema.validate(values, { abortEarly: false });
  }
};

export const handleValidationError = (e, formikBag) => {
  const errors = yupToFormErrors(e);
  formikBag.setErrors(errors);
};

export const handleApiValidationError = (e, formikBag, config) => {
  let errors = e.data;
  if (config.namespace) {
    errors = { [config.namespace]: errors };
  }
  formikBag.setErrors(errors);
};

export const handleApiError = (e, formikBag) => {
  formikBag.setErrors({ _error: e.message });
};
export function yupToFormErrors(yupError) {
  let errors = {};
  if (yupError.inner) {
    if (yupError.inner.length === 0) {
      return set(errors, yupError.path, yupError.message);
    }
    for (let err of yupError.inner) {
      if (!get(errors, err.path)) {
        errors = set(errors, err.path, err.message);
      }
    }
  }
  return errors;
}

export const handleValidationSchemaError = (e) => {
  if (e.name === "ValidationError") {
    const name = e.path;
    const $errorElement = document.querySelector(`[name="${name}"]`);
    scrollToErrorElement($errorElement);
  }
};
const scrollToErrorElement = ($element) => {
  if ($element) {
    setTimeout(() => {
      $element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 0);
  }
};

//
