import { compose, defaultProps, withHooks } from "enhancers";
import { Box } from "components";
import { DatePicker as FormikMuiDatePicker } from "formik-material-ui-pickers";
import { format } from "date-fns";

import { ReactComponent as DatePickerIcon } from "assets/icon/datepicker_icon.svg";
import T from "./T";
import { replaceNameSpace } from "utils/helper";

const DatePicker = (props: any) => (
  <Box position="relative">
    <FormikMuiDatePicker {...props} />
    <Box position="absolute" top={16} right={12}>
      <DatePickerIcon />
    </Box>
  </Box>
);

const enhancer = compose(
  defaultProps({ format: "dd/MM/yyyy" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo } = hooks;
    const FormHelperTextProps = useMemo(
      () => ({
        component: (props: any) => (
          <T className={props.className}>
            {replaceNameSpace("yup.error", props.children)}
          </T>
        ),
      }),
      []
    );
    return {
      ...props,
      labelFunc: (date: Date, invalidLabel: string) =>
        props.field.value ? format(date, props.format) : "",
      FormHelperTextProps,
    };
  })
);

export default enhancer(DatePicker);
