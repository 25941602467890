import { compose, withHooks } from "enhancers";
import { FormControlLabel, Typography } from "@material-ui/core";
import { Checkbox as FormikMuiCheckbox } from "formik-material-ui";

import { ReactComponent as CheckboxOutlineIcon } from "assets/icon/checkbox_outline.svg";
import { ReactComponent as CheckboxOutlineCheckedIcon } from "assets/icon/checkbox_outline_checked.svg";

const CheckboxCustoms = ({ className, label, ...checkboxProps }: any) => (
  <FormControlLabel
    className={className}
    control={
      <FormikMuiCheckbox
        icon={<CheckboxOutlineIcon />}
        checkedIcon={<CheckboxOutlineCheckedIcon />}
        color="primary"
        size="small"
        indeterminate={false}
        style={{ marginLeft: 5 }}
        {...checkboxProps}
      />
    }
    label={
      <Typography variant="body1" align="center">
        {label}
      </Typography>
    }
  />
);

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { field } = props || {};
    const { value: fieldValue } = field || {};
    const { useState, useEffect } = hooks || {};

    const [checked, setChecked] = useState(false);
    const [value, setValue] = useState(false);

    useEffect(() => {
      setValue(fieldValue);
      setChecked(fieldValue);
    }, [fieldValue]);

    return { ...props, value, checked };
  })
);

export default enhancer(CheckboxCustoms);
