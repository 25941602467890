import { compose, withHooks, withFormik } from "enhancers";
import { Box, Field, Select } from "components";
import { range, map, toString, isString } from "lodash";
import { format, parseISO, isValid, lastDayOfMonth, isEqual } from "date-fns";
import {
  adjustDateValue,
  adjustDayValue,
  setAdjustedValues,
  sprintf,
  Yup,
} from "utils/helper";

const DateSelector = (props: any) => (
  <Box display="flex" {...props}>
    <Field
      component={Select}
      name="day"
      label="วันเกิด"
      width={`${(100 / (100 + 246 + 150)) * 100}%`}
      options={props.dayOptions}
    />
    <Box m={1} />
    <Field
      component={Select}
      name="month"
      label="เดือน"
      width={`${(246 / (100 + 246 + 150)) * 100}%`}
      options={props.monthOptions}
    />
    <Box m={1} />
    <Field
      component={Select}
      name="year"
      label="ปี"
      width={`${(150 / (100 + 246 + 150)) * 100}%`}
      options={props.yearOptions}
    />
  </Box>
);

const enhancer = compose(
  withFormik({
    validationSchema: Yup.object().shape({
      day: Yup.string().required(),
      month: Yup.string().required(),
      year: Yup.string().required(),
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useEffect } = hooks;
    const { setFieldValue, values, form, field } = props;

    const { year, month, day } = values;

    const parseFieldValue = (value: string | Date): Date => {
      if (isString(value)) {
        return parseStringDate(value);
      }
      return value;
    };
    const parseStringDate = (value: string): Date => {
      return parseISO(value);
    };
    const outerFieldName = field.name;
    const outerFieldValue = parseFieldValue(field.value);
    const outerSetFieldValue = form.setFieldValue;

    const yearOptions = useMemo(() => {
      return map(range(1900, new Date().getFullYear() + 1), (i) => ({
        label: toString(i + 543),
        value: i,
      }));
    }, []);

    const monthOptions = useMemo(() => {
      return map(range(1, 12 + 1), (i) => ({
        label: [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
        ][i - 1],
        value: i,
      }));
    }, []);
    const createMockDate = (
      year: number | undefined,
      month: number | undefined
    ): Date => {
      const formattedYear = year ?? 0;
      const formattedMonth = month ?? 0;
      const dateString = sprintf("%d-%02d-01", formattedYear, formattedMonth);
      return parseISO(dateString);
    };
    const mockDate = createMockDate(year, month);
    let lastDay = 31; // Default value
    if (isValid(mockDate)) {
      const lastDateOfMonth = lastDayOfMonth(mockDate);
      lastDay = lastDateOfMonth.getDate();
    }
    const dayOptions = useMemo(() => {
      return map(range(1, lastDay + 1), (i) => ({
        label: sprintf("%02d", i),
        value: i,
      }));
    }, [lastDay]);

    useEffect(() => {
      const adjustDay = adjustDayValue(day, lastDay);
      const adjustDate = adjustDateValue(year, month, adjustDay ?? 0);

      setAdjustedValues(
        setFieldValue,
        outerSetFieldValue,
        outerFieldName,
        day,
        adjustDay,
        outerFieldValue,
        adjustDate
      );
    }, [
      outerSetFieldValue,
      outerFieldName,
      outerFieldValue,
      setFieldValue,
      day,
      year,
      month,
      lastDay,
    ]);

    let outerFieldValueString = "";
    if (outerFieldValue) {
      outerFieldValueString = format(outerFieldValue, "dd/MM/yyyy");
    }
    useEffect(() => {
      if (!outerFieldValueString) {
        return;
      }

      let outerDay, outerMonth, outerYear;
      try {
        outerDay = outerFieldValue.getDate();
        outerMonth = outerFieldValue.getMonth() + 1;
        outerYear = outerFieldValue.getFullYear();
      } catch (e) {
        outerDay = null;
        outerMonth = null;
        outerYear = null;
      }

      setFieldValue("day", outerDay);
      setFieldValue("month", outerMonth);
      setFieldValue("year", outerYear);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outerFieldValueString]);

    return {
      dayOptions,
      monthOptions,
      yearOptions,
    };
  })
);

export default enhancer(DateSelector);
