import { GridColDef } from "@material-ui/data-grid";
import { ReactComponent as FilterIcon } from "assets/icon/filter_outline.svg";
import {
  Box,
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Modal,
  Select,
  Table,
  TextField,
  Typography,
} from "components";
import { compose, withFormik, withHooks, withTranslation } from "enhancers";
import { PageContent } from "layouts";
import { BreadcrumbsProps } from "layouts/PageContent";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { gql, homePath, paths } from "utils/helper";

import { CircularProgress } from "@material-ui/core";

const FilterCountNotify = styled(Box)`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffa500;
  display: flex;
`;

const EmployeeIndexPage = (props: any) => (
  <PageContent
    title={props.t(".title")}
    breadcrumbs={props.breadcrumbs}
    // pageActions={[
    //   {
    //     children: props.t(".syncButton"),
    //     startIcon: <SyncIcon />,
    //     onClick: props.handleClickSyncEmployee,
    //     color: "primary",
    //     permittedRoles: [],
    //   },
    // ]}
    filter={props.filterSection}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        {props.t(".title")}
      </Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.employeeEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_EMPLOYEES: gql`
    query FETCH_EMPLOYEES($filters: JSON) {
      getEmployees(input: { filters: $filters }) {
        id
        employeeCode
        firstName
        lastName
        phoneNumber
        role
        title
        userName
        birthDate
        gender
        maritalStatus
        nationality
        officialMailId
        employmentStartDate
        employmentFinishDate
        department
        probationStatus
        probationPeriod
        costCenterName
      }
    }
  `,
  FETCH_OPTIONS: gql`
    query FETCH_OPTIONS {
      getDepartmentOption {
        label
        value
      }
    }
  `,
  SYNC_EMPLOYEES: gql`
    mutation SYNC_EMPLOYEES {
      syncEmployees
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withTranslation({
    prefix: "pages.main.employee.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { t, setValues, setInitialValues } = props;
    const {
      useState,
      useMemo,
      useCallback,
      useQuery,
      useEffect,
      useHandleSubmit,
      useMutation,
    } = hooks;

    useEffect(() => {
      setInitialValues({
        department: "ทั้งหมด",
      });
    }, []);

    const [showFilter, setShowFilter] = useState(false);
    const [filterCount, setFilterCount] = useState(0);

    const { loading, data, error, refetch } = useQuery(API.FETCH_EMPLOYEES, {
      fetchPolicy: "network-only",
    });

    const { data: options } = useQuery(API.FETCH_OPTIONS, {
      fetchPolicy: "network-only",
    });

    const [syncEmployees] = useMutation(API.SYNC_EMPLOYEES, {
      onCompleted: async () => {
        Modal.close();
        await refetch();
      },
    });

    const departmentOption = useMemo(() => {
      return [{ label: "ทั้งหมด", value: "ทั้งหมด" }].concat(
        options?.getDepartmentOption
      );
    }, [options]);

    const handleShowFilter = useCallback(() => {
      setShowFilter(!showFilter);
    }, [showFilter]);

    const clearFilter = useCallback(async () => {
      setValues({
        code: "",
        fullName: "",
        department: "ทั้งหมด",
        employmentStartDate: null,
        employmentStartDateRangeEnd: null,
        employmentFinishDate: null,
        employmentFinishDateRangeEnd: null,
      });
      const variables = {
        filters: {
          code: "",
          fullName: "",
          department: "ทั้งหมด",
          employmentStartDate: null,
          employmentStartDateRangeEnd: null,
          employmentFinishDate: null,
          employmentFinishDateRangeEnd: null,
        },
      };
      setFilterCount(0);
      await refetch(variables);
    }, [setValues]);

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    const filterSection = useMemo(
      () => (
        <Box>
          <Form>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <FilterIcon />
                <Typography variant="h6" mx={2}>
                  {t(".filter.title")}
                </Typography>
                {filterCount > 0 && (
                  <FilterCountNotify>
                    <Typography variant="body1">{filterCount}</Typography>
                  </FilterCountNotify>
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                onClick={handleShowFilter}
              >
                <Typography
                  variant="body1"
                  style={{
                    textDecoration: "underline",
                    color: AppColor["Primary/Primary Text"],
                    cursor: "pointer",
                  }}
                >
                  {showFilter ? t(".filter.hide") : t(".filter.show")}
                </Typography>
              </Box>
            </Box>
            {showFilter && (
              <Box display="flex" flexDirection="column">
                <Grid container spacing={6} mt={6}>
                  <Grid item xs={3}>
                    <Field
                      component={TextField}
                      name="code"
                      type="text"
                      label={t(".filter.code")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextField}
                      name="fullName"
                      type="text"
                      label={t(".filter.fullName")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Select}
                      name="department"
                      type="text"
                      label={t(".filter.department")}
                      options={departmentOption}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={DatePicker}
                      name="employmentStartDate"
                      label={t(".filter.employmentStartDate")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={DatePicker}
                      name="employmentStartDateRangeEnd"
                      label={t(".filter.endDate")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={DatePicker}
                      name="employmentFinishDate"
                      label={t(".filter.employmentFinishDate")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={DatePicker}
                      name="employmentFinishDateRangeEnd"
                      label={t(".filter.endDate")}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Box display="flex" flexDirection="row-reverse" mt={4}>
                  <Button width={74} type="submit">
                    {t(".filter.confirm")}
                  </Button>
                  <Button
                    width={74}
                    mr={6}
                    onClick={clearFilter}
                    variant="outlined"
                  >
                    {t(".filter.reset")}
                  </Button>
                </Box>
              </Box>
            )}
          </Form>
        </Box>
      ),
      [t, showFilter, handleShowFilter, filterCount]
    );

    useHandleSubmit(
      async (values: any) => {
        if (values.department === "ทั้งหมด") {
          setFilterCount(
            Math.max(Object.values(values).filter(Boolean).length - 1, 0)
          );
        } else {
          setFilterCount(Object.values(values).filter(Boolean).length);
        }

        const variables = {
          filters: {
            code: values.code,
            fullName: values.fullName,
            department: values.department,
            employmentStartDate: values.employmentStartDate,
            employmentStartDateRangeEnd: values.employmentStartDateRangeEnd,
            employmentFinishDate: values.employmentFinishDate,
            employmentFinishDateRangeEnd: values.employmentFinishDateRangeEnd,
          },
        };
        await refetch(variables);
      },
      [refetch]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.getEmployees.map((employee: any) => {
        const { firstName, lastName, officialMailId, employeeCode } = employee;

        return {
          ...employee,
          code: employeeCode,
          email: officialMailId,
          fullName:
            (isEmpty(firstName) || firstName == null) &&
            (isEmpty(lastName) || lastName == null)
              ? null
              : `${firstName || ""} ${lastName || ""}`,
        };
      });
    }, [loading, error, data, t]);

    const columns = useMemo(
      (): GridColDef[] => [
        {
          width: 130,
          field: "code",
          headerName: t(".columns.code"),
        },
        {
          width: 240,
          field: "fullName",
          headerName: t(".columns.fullName"),
        },
        {
          width: 140,
          field: "department",
          headerName: t(".columns.department"),
        },
        {
          width: 220,
          field: "email",
          headerName: t(".columns.email"),
        },
        {
          width: 120,
          field: "employmentStartDate",
          headerName: t(".columns.employmentStartDate"),
          type: "dateOnly",
        },
        {
          width: 200,
          field: "employmentFinishDate",
          headerName: t(".columns.employmentFinishDate"),
          type: "dateOnly",
        },
      ],
      [t]
    );

    const handleClickSyncEmployee = useCallback(async () => {
      Modal.open({
        title: "",
        children: (
          <div
            style={{
              objectFit: "contain",
              padding: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box mb="24px">
              <Typography>{t(".syncEmployeeLoading")}</Typography>
            </Box>
            <CircularProgress />
          </div>
        ),
        disableBackdropClick: true,
        hideFooter: true,
      });
      await syncEmployees();
      Modal.close();
    }, [syncEmployees, t]);

    return {
      breadcrumbs,
      tableData,
      loading,
      columns,
      handleShowFilter,
      showFilter,
      filterSection,
      handleClickSyncEmployee,
    };
  })
);

export default enhancer(EmployeeIndexPage);
