import { Box, Button, Icon, IconButton, Modal, Typography } from "components";
import { compose, withHooks, withStores, withTranslation } from "enhancers";
import { AppColor } from "theme/app-color";
import { TFunction } from "i18next";
import { ReactComponent as BigTrashIcon } from "assets/icon/big-trash-icon.svg";
import { ReactComponent as FileIcon } from "assets/icon/attach-file-icon.svg";
import { forceDownload } from "utils/helper";
import imageCompression from "browser-image-compression";
interface UploadFileProps {
  t: TFunction;
  download: (name: any, file: any) => void;
  onChange: (e: any) => void;
  deleteFile: () => void;
  fileName: string;
  fileSave: any;
  isFileExist: boolean;
  uploadButtonRightSide: boolean;
}

const UploadFile = (props: UploadFileProps) => (
  <Box {...props}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {props.isFileExist ? (
        <Box display="flex">
          <Box
            onClick={() => {
              window.open(props.fileSave, "_blank");
            }}
          >
            <Typography
              variant="body"
              color={AppColor["Text/Blue"]}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {props.fileName}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              props.deleteFile();
            }}
            color={AppColor["Other/Danger"]}
            display="flex"
            alignItems="center"
          >
            <BigTrashIcon
              style={{
                width: "16px",
                height: "16px",
                marginLeft: "8px",
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          <Button
            visible={false}
            variant="outlined"
            component="label"
            style={{ height: "34px", borderRadius: "6px", width: "250px" }}
            loading={true}
          >
            <FileIcon
              style={{
                width: "24px",
                height: "24px",
                marginRight: "8px",
              }}
            />
            {`แนบไฟล์`}
            <input
              type="file"
              accept="image/png, image/jpeg"
              hidden
              onChange={(e) => {
                props.onChange(e);
              }}
            />
          </Button>
          <Typography
            variant="caption"
            color={AppColor["Text Light/Secondary"]}
            style={{ marginLeft: "auto", marginTop: "6px" }}
          >
            {`(รองรับไฟล์ jpg/png)`}
          </Typography>
        </Box>
      )}
    </Box>
  </Box>
);

const enhancer = compose(
  withStores((stores: any) => ({
    setInitialValues: stores.appStore.setInitialValues,
  })),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useEffect, useState } = hooks;
    const {
      field,
      form,
      uploadButtonRightSide = true,
      setInitialValues,
      t,
      onLoading,
      ...rest
    } = props;
    const { setFieldValue, values } = form || {};
    const { value: fieldValue } = field || {};
    const { name: fieldName } = field || {};

    const [fileName, setFilename] = useState("");
    const [fileSave, setFile] = useState("");
    const [isFileExist, setFileExist] = useState(false);

    const download = useCallback((name: any, url: any) => {
      if (name) {
        forceDownload(url, name);
      }
    }, []);

    const deleteFile = useCallback(() => {
      Modal.open({
        title: `ลบข้อมูล`,
        children: (
          <Box>
            <Typography
              variant="body1"
              color={AppColor["Text/Secondary"]}
              mb="4px"
            >
              {`ข้อมูล รายละเอียดแบบประกันสำหรับแสดงใน App  จะถูกลบและไม่สามารถกู้คืนได้`}
            </Typography>
          </Box>
        ),
        cancelButtonLabel: `ปิดหน้าต่างนี้`,
        cancelButtonVariant: "text",
        okButtonLabel: `ลบข้อมูล`,
        okButtonVariant: "outlined",
        okButtonColor: AppColor["Other/Danger"],
        okButtonBackgroundHover: AppColor["System/Error Light Hover Button"],
        onOk: async ({ close }: any) => {
          setFilename("");
          setFile("");
          setFileExist(false);
          setFieldValue(fieldName, null);
          close();
        },
      });
    }, [fieldName, setFilename, setFile, setFileExist, setFieldValue]);

    const compressImage = useCallback(async (image: any) => {
      const maxSizeMB = 1;
      try {
        const compressedFile = await imageCompression(image, {
          maxSizeMB: maxSizeMB,
          useWebWorker: true,
        });
        const compressedFileSizeMB = compressedFile.size / 1024 / 1024;
        if (compressedFileSizeMB > maxSizeMB) {
          return await compressImage(compressedFile);
        }
        return compressedFile;
      } catch (error) {
        console.error("Failed to compress image:", error);
        throw error;
      }
    }, []);
    const onChange = useCallback(
      async (e: any) => {
        const fileObj = e.target.files[0];
        const file = new File([fileObj], fileObj?.name, {
          type: fileObj.type,
        });
        setFilename(fileObj.name);
        onLoading && onLoading(true);
        const fileResize = await compressImage(file);
        onLoading && onLoading(false);
        setFile(URL.createObjectURL(fileResize));
        setFileExist(true);
        if (fieldName) {
          setFieldValue(fieldName, fileResize);
        }
      },
      [compressImage, fieldName, setFieldValue]
    );
    useEffect(() => {
      async function fetchData() {
        if (fieldValue?.filename && fieldValue?.url) {
          const loadFile = async () => {
            const blob = await fetch(fieldValue?.url).then((r) => r.blob());
            const file = new File([blob], fieldValue?.filename, {
              type: blob.type,
            });
            const fileResize = await compressImage(file);
            setInitialValues({
              ...values,
              [fieldName]: fileResize,
            });
            const src =
              file instanceof File ? URL.createObjectURL(fileResize) : null;
            setFile(src);
            setFileExist(true);
            setFilename(fieldValue?.filename);
          };
          loadFile();
        }
        if (fieldValue instanceof File) {
          const src =
            fieldValue instanceof File ? URL.createObjectURL(fieldValue) : null;
          setFile(src);
          setFileExist(true);
          setFilename(fieldValue?.name);
        }
      }

      fetchData();
    }, [compressImage, fieldName, fieldValue, setInitialValues, values]);

    return {
      ...rest,
      fileName,
      download,
      deleteFile,
      onChange,
      fileSave,
      isFileExist,
      uploadButtonRightSide,
    };
  })
);

export default enhancer(UploadFile);
