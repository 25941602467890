const th = {
  translation: {
    client: {
      enhancers: {
        withPreventLeaveDirtyForm: {
          cancelEdit: "ยกเลิกการแก้ไขข้อมูล",
          cancelConfirm: "ข้อมูลปัจจุบันจะไม่ถูกบันทึก และไม่สามารถกู้คืนได้",
          close: "ปิดหน้าต่างนี้",
          canceledEdit: "ยกเลิกการแก้ไข",
        },
      },
      api: {
        modal401Title: "การเข้าสู่ระบบหมดอายุ",
        modal401Children:
          "การเข้าสู่ระบบหมดอายุ กรุณาทำการเข้าสู่ระบบใหม่อีกครั้ง",
        modal401OkButtonLabel: "เข้าสู่ระบบ",
        modal403Title: "เกิดข้อผิดพลาด",
        modal403Children:
          "บทบาทผู้ใช้งานของคุณไม่มีสิทธิ์ใช้งานส่วนนี้ กรุณาเข้าสู่ระบบอีกครั้ง",
        modal403OkButtonLabel: "เข้าสู่ระบบ",
        modal500Title: "เกิดข้อผิดพลาด",
        modal500OkButtonLabel: "ตกลง",
      },
      pages: {
        main: {
          admins: {
            index: {
              title: "ผู้ดูแลระบบ",
              home: "หน้าแรก",
              addButton: "เพิ่มผู้ดูแลระบบ",
              admins: "รายชื่อผู้ดูแลระบบ",
              deleteButton: "ต้องการลบข้อมูลผู้ดูแลระบบ",
              columns: {
                fullName: "ชื่อ นามสกุล",
                email: "อีเมล",
                status: "สถานะ",
                code: "รหัส",
                lastActiveAt: "ใช้งานล่าสุด",
                createdAt: "สร้างเมื่อ",
                updatedAt: "แก้ไขเมื่อ",
                role: "บทบาท",
                values: {
                  availableStatus: {
                    active: "ใช้งาน",
                    inactive: "ไม่ใช้งาน",
                  },
                },
              },
            },
            new: {
              home: "หน้าแรก",
              rootPath: "ผู้ดูแลระบบ",
              title: "เพิ่มผู้ดูแลระบบ",
              header: "กรอกอีเมลผู้ดูแลระบบเพื่อส่งลิงค์เข้าใช้งาน",
              link: "ลิงก์เพื่อเข้าใช้งานระบบจะถูกส่งไปที่อีเมลนี้",
              send: "ส่งเมล",
              cancel: "ยกเลิก",
              email: "อีเมล",
              role: "บทบาท",
              notificationSuccess: "ทำรายการสำเร็จ",
            },
            edit: {
              title: "ข้อมูลทั่วไป",
              home: "หน้าแรก",
              rootPath: "ผู้ดูแลระบบ",
              status: "สถานะ",
              cancel: "ยกเลิก",
              save: "บันทึก",
              cantEditYourself:
                "การแก้ไขสถานะและบทบาทไม่สามารถทำได้ด้วยตัวเอง ต้องให้ Admin ที่มี permission แก้ไข permission ของ Admin ทำให้เท่านั้น เช่น Super Admin",
              firstName: "ชื่อ",
              lastName: "นามสกุล",
              role: "บทบาท",
              email: "อีเมล",
              available: "ใช้งานระบบ",
            },
          },
          authentication: {
            LoginPage: {
              or: "หรือ",
              title: "E-Claim",
              title1: "กรอกอีเมลรับรหัส OTP เพื่อเข้าสู่ระบบ",
              title2: "กรอกอีเมลรับรหัส OTP เพื่อลงทะเบียนใช้งาน",
              title3: "กรอกอีเมลและรหัสผ่านเพื่อเข้าสู่ระบบ",
              emailField: "อีเมล",
              passwordField: "รหัสผ่าน",
              getOtp: "รับรหัส OTP",
              invalidEmail: "รูปแบบอีเมลไม่ถูกต้อง",
              signInWithAzureAd: "เข้าสู่ระบบด้วย Azure AD",
              logoutAzureAd: "ออกจากระบบ Azure AD",
              ResetPassword: "ลืมรหัสผ่าน",
              login: "เข้าสู่ระบบ",
            },
            VerifyOtp: {
              title: "กรอกรหัส OTP เพื่อเข้าสู่ระบบ",
              otpInfo:
                "กรอกรหัส OTP ที่ได้รับทาง {{currentEmail}} (OTP มีอายุการใช้งาน {{otpExpireInMin}} นาที)",
              otp: "OTP",
              refCode: "เลขอ้างอิง:{{refCode}}",
              notReceiveOtp: "ไม่ได้รหัส OTP?",
              retryOtp: "ขอ OTP อีกครั้ง",
              canRetryIn: "ขออีกครั้งใน {{timeLeft}} วินาที",
              login: "เข้าสู่ระบบ",
              changeEmail: "เปลี่ยนอีเมล",
              home: "กลับไปหน้าแรก",
              otpMustNumber: "รูปแบบ otp ไม่ถูกต้อง",
            },
            ResetPassword: {
              home: "กลับไปหน้าแรก",
              title: "กรอกที่อยู่อีเมล",
              title2: "ระบบจะส่งลิงก์สำหรับตั้งรหัสผ่านใหม่ไปทางอีเมล",
              emailField: "อีเมล",
              resetPassword: "รีเซตรหัสผ่าน",
              modal: {
                title: "ส่งอีเมลสำเร็จ",
                content: "โปรดตรวจสอบอีเมลของคุณเพื่อตั้งรหัสผ่านใหม่",
                button: "ตกลง",
              },
            },
            SetupPassword: {
              title: "สร้างรหัสผ่านสำหรับ",
              passwordField: "รหัสผ่าน",
              confirmPasswordField: "ยืนยันรหัสผ่าน",
              setupPassword: "สร้างรหัสผ่าน",
              suggestText: {
                1: "ต้องยาว 8-16 หลัก",
                2: "ตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว",
                3: "ตัวเลขอย่างน้อย 1 ตัว",
                4: "ไม่รองรับภาษาไทยและสัญลักษณ์พิเศษ",
              },
              modal: {
                title: "ตั้งรหัสผ่านใหม่สำเร็จ",
                content: "โปรดเข้าสู่ระบบเพื่อใช้งาน",
                button: "ตกลง",
                error: {
                  title: "เกิดข้อผิดพลาด",
                  button: "ตกลง",
                },
              },
            },
          },
          roles: {
            index: {
              title: "บทบาท",
              home: "หน้าแรก",
              addButton: "เพิ่มบทบาท",
              list: "รายการบทบาท",
              deleteRole: "ข้อมูล บทบาท จะถูกลบและไม่สามารถกู้คืนได้",
              deletedRole: "ลบข้อมูล",
              description: "คำอธิบาย",
              amount: "จำนวนผู้ใช้",
              updatedAt: "แก้ไขล่าสุด",
              deleteRoleSuccess: "ลบข้อมูลสำเร็จ",
              alreadyInUse: "ไม่สามารถลบได้ มีผู้ดูแลระบบใช้งานอยู่",
              cancel: "ปิดหน้าต่างนี้",
              delete: "ลบข้อมูล",
            },
            new: {
              addRole: "เพิ่มบทบาท",
              role: "บทบาท",
              home: "หน้าแรก",
              title: "ข้อมูลทั่วไป",
              detail: "รายละเอียดบทบาท",
              scope: "ขอบเขตการใช้งานระบบ",
              reportScope: "ขอบเขตการออกรายงาน",
              roleTitle: "ชื่อบทบาท",
              description: "คำอธิบาย",
              syncingNotification: "รับแจ้งเตือนเมื่อการ Sync ไม่สำเร็จ",
              read: "ดูข้อมูล",
              edit: "แก้ไขข้อมูล",
              admin: "จัดการ Admin",
              permission: "จัดการ Permission",
              report: "จัดการ Report",
              setting: "จัดการ Setting",
              employee: "จัดการ Employee",
              budget: "จัดการ Budget",
              request: "จัดการ Request",
              exportAllReport: "ออกรายงานได้ทั้งหมด",
              medical: "ค่ารักษาพยาบาล",
              medicalReimbursementPerEmployee:
                "รายงานการเบิกค่ารักษาพยาบาล (รายพนักงาน)",
              medicalReimbursementDepartment:
                "รายงานการเบิกค่ารักษาพยาบาลตามฝ่าย/สังกัด",
              support: "ค่าช่วยเหลือบุตร",
              supportChildPerEmployee: "รายงานเงินช่วยเหลือบุตร (รายพนักงาน)",
              supportChildUnder18PerEmployee:
                "รายงานเงินช่วยเหลือบุตรที่ยังไม่เกิน 18 ปีบริบูรณ์ (รายพนักงาน)",
              supportChildHistoryDisbursement:
                "รายงานประวัติการเบิกเงินช่วยเหลือบุตร",
              education: "ค่าช่วยเหลือการศึกษาบุตร",
              educationChildPerEmployee:
                "รายงานการเบิกค่าช่วยเหลือการศึกษาบุตร (รายพนักงาน)",
              compensation: "เงินชดเชยพนักงาน",
              compensationFuneralDisbursementSupport:
                "รายงานการเบิกค่าช่วยเหลือจัดการงานศพ (3 เท่า) ",
              compensationFuneralDisbursement: "รายงานการเบิกค่าเจ้าภาพงานศพ",
              compensationFuneralDisbursementEquipment:
                "รายงานการเบิกค่าอุปกรณ์เคารพศพ",
              summary: "รายงานสรุปสำหรับส่งงานการเงินและบัญชี",
              summaryMedicalReimbursementDepartment:
                "สรุปการเบิกค่ารักษาพยาบาลตามฝ่าย/สังกัด",
              summaryChildEducationReimbursementDepartment:
                "สรุปการเบิกค่าเล่าเรียนบุตรตามฝ่าย/สังกัด",
              summaryChildSupportReimbursementDepartment:
                "สรุปการเบิกเงินช่วยเหลือบุตรตามฝ่าย/สังกัด",
              summaryAllEachTimes: "สรุปรายการเบิกทั้งหมด รายครั้ง",
              affect: "รายงานกระทบยอดการเบิก",
              affectChildSupportIncrease:
                "รายงานเงินช่วยเหลือบุตร (เพิ่มขึ้น) ตามฝ่าย/สังกัด",
              affectChildSupportReduce:
                "รายงานเงินช่วยเหลือบุตร (ลดลง) ตามฝ่าย/สังกัด",
              cancel: "ยกเลิก",
              save: "บันทึก",
              cancelEdit: "ยกเลิกการแก้ไขข้อมูล",
              cancelConfirm:
                "ข้อมูลปัจจุบันจะไม่ถูกบันทึก และไม่สามารถกู้คืนได้",
              close: "ปิดหน้าต่างนี้",
              canceledEdit: "ยกเลิกการแก้ไข",
            },
            edit: {
              home: "หน้าแรก",
              indexTitle: "บทบาท",
              editRole: "แก้ไขบทบาท",
              cancelEdit: "ยกเลิกการแก้ไขข้อมูล",
              cancelConfirm:
                "ข้อมูลปัจจุบันจะไม่ถูกบันทึก และไม่สามารถกู้คืนได้",
              close: "ปิดหน้าต่างนี้",
              canceledEdit: "ยกเลิกการแก้ไข",
              detail: "รายละเอียดบทบาท",
              scope: "ขอบเขตการใช้งานระบบ",
              cancel: "ยกเลิก",
              save: "บันทึก",
              roleTitle: "ชื่อบทบาท",
              description: "คำอธิบาย",
            },
          },
          insurances: {
            index: {
              home: "หน้าแรก",
              title: "จัดการชุดแบบประกัน",
              addButton: "สร้างชุดแบบประกัน",
              list: "ชุดแบบประกัน",
              year: "ปีที่ใช้งาน",
              amount: "จำนวนแบบประกัน",
              updatedAt: "วันที่แก้ไขล่าสุด",
              status: "สถานะ",
              modalDescription:
                "โปรดระบุปีที่ใช้งาน (ปีที่ใช้งานต้องไม่ซ้ำกับที่มีอยู่แล้วในระบบ)",
              duplicateTitle: "คัดลอกชุดแบบประกัน",
              duplicateDescription:
                "ระบบจะคัดลอกชุดแบบประกัน {{year}} มาสร้างเป็นต้นแบบของชุดแบบประกันในชื่อ {{secondYear}} เพื่อให้แก้ไขและใช้งานต่อไป",
              cancel: "ยกเลิก",
              duplicateSuccess: "ทำรายการสำเร็จ",
              alreadyInUse: "ไม่สามารถลบได้ มีชุดแบบประกันปีนี้แล้ว",
              reset: "รีเซ็ต",
              filter: "กรอง",
              reEditing: "แก้ไขการจัดชุดประกันที่เผยแพร่แล้ว",
              reEditingInfo:
                "การแก้ไขจะทำให้ HR ไม่สามารถสร้าง enrollment ด้วยชุดประกันนี้ได้ จนกว่าจะมีการเผยแพร่ชุดประกันใหม่อีกครั้ง",
              edit: "แก้ไข",
              deleteTitle: "ลบชุดแบบประกัน",
              deleteSuccess: "ทำรายการสำเร็จ",
              deleteDescription: "ต้องการลบข้อมูลชุดแบบประกันปี {{year}}",
              state: {
                all: "ทั้งหมด",
                draft: "แบบร่าง",
                published: "เผยแพร่",
                editing: "แก้ไข",
                active: "ใช้งาน",
                closed: "ปิด",
              },
            },
            package: {
              new: {
                save: "บันทึก",
                cancel: "ยกเลิก",
                name: "ชื่อ Package",
                ipd: "ประกัน IPD",
                opd: "ประกัน OPD",
                dental: "ประกัน Dental",
                remark: "หมายเหตุ",
              },
            },
          },
          settings: {
            index: {
              home: "หน้าแรก",
              settingMenu: "การตั้งค่าระบบ",
              title: "การพิจารณาคำร้อง",
              titleDetail:
                "แสดงรูปภาพรายละเอียดระยะเวลาพิจารณาการเบิกสวัสดิการ",
              pageDetail: "หน้าที่แสดงลิงก์เพื่อดูรายละเอียด",
              imageDetail: "ภาพรายละเอียดการพิจารณา",
              isShowSuccesRequest: "แสดงเมื่อส่งคำร้องสำเร็จ",
              isShowHistoryPage: "แสดงในหน้าประวัติคำร้อง",
              isShowLandingPage: "แสดงในหน้าแรก",
              syncSetting: "รอบการเบิก",
              syncSettingDetail: "ช่วงเวลาที่พนักงานสามารถเบิกสวัสดิการได้",
              reportSetting: "ตั้งค่าการสร้างรายงานอัตโนมัติ",
              reportSettingDetail: "ใช้ในขั้นตอนการสร้างรายงานอัตโนมัติของระบบ",
              syncCloseData: "วันที่ปิดรอบการเบิกของแต่ละปี",
              notifyEmployee: "การแจ้งเตือนพนักงาน",
              isNotifyEmployee: "แจ้งเตือนพนักงานให้ส่งเรื่องเบิกก่อนปิดรอบ",
              startDateNotify:
                "วันที่เริ่มแจ้งเตือนพนักงานให้ส่งเรื่องเบิกก่อนปิดรอบ",
              addImage: "เพิ่มรูป",
              editImage: "แก้ไข",
              name: "ชื่อบริษัท",
              nameTh: "ชื่อบริษัท (Th)",
              nameEn: "ชื่อบริษัท (En)",
              description: "รายละเอียดบริษัท",
              descriptionTh: "รายละเอียดบริษัท (Th)",
              descriptionEn: "รายละเอียดบริษัท (En)",
              email: "อีเมล",
              phoneNumber: "เบอร์โทรศัพท์",
              cancel: "ยกเลิก",
              save: "บันทึก",
              saveSuccess: "บันทึกข้อมูลสำเร็จ",
              syncOpenData: "วันที่เปิดรอบการเบิกของแต่ละปี",
              cancelEdit: "ยกเลิกการแก้ไขข้อมูล",
              cancelConfirm:
                "ข้อมูลปัจจุบันจะไม่ถูกบันทึก และไม่สามารถกู้คืนได้",
              close: "ปิดหน้าต่างนี้",
              canceledEdit: "ยกเลิกการแก้ไข",
            },
          },
          reports: {
            index: {
              home: "หน้าแรก",
              reportMenu: "ออกรายงาน",
              report: "สร้างรายงานกระทบยอดการเบิก",
              reportSummary: "สร้างรายงานสรุป",
              filter: "กรอง",
              filterTitle: "ตัวกรอง",
              remove: "ลบสำเร็จ",
              removetTitle: "ลบรายงาน",
              show: "แสดง",
              hide: "ซ่อน",
              reset: "รีเซ็ท",
              removeButtonOK: "ลบรายงาน",
              removeButtonCancel: "ปิดหน้าต่างนี้",
              downloadSuccess: "ดาวน์โหลดรายงานสำเร็จ",
              columns: {
                year: "ปีที่ใช้งาน",
                reportName: "ชื่อรายงาน",
                reportType: "ประเภทรายงาน",
                startDate: "วันที่เริ่มต้น",
                endDate: "วันที่สิ้นสุด",
                state: "สถานะ",
                createdAt: "วันที่สร้าง",
              },
              modal: {
                title: "ปรับสถานะรายงาน",
                description: "ปรับสถานะการส่งรายงานเป็น",
                statusSend: "ส่งแล้ว",
                statusNotSend: "ยังไม่ได้ส่ง",
                cancelButtonLabel: "ยกเลิก",
                okButtonLabel: "ยืนยัน",
                generating: "กำลังสร้างรายงาน",
              },
            },
          },
          employee: {
            index: {
              home: "หน้าแรก",
              title: "พนักงาน",
              syncButton: "อัพเดตข้อมูลพนักงาน",
              filter: {
                title: "ตัวกรอง",
                show: "แสดง",
                hide: "ซ่อน",
                fullName: "ชื่อ นามสกุล",
                email: "อีเมล",
                status: "สถานะ",
                code: "รหัส",
                department: "ฝ่าย/สังกัด",
                employmentStartDate: "วันที่เริ่มงานตั้งแต่",
                employmentFinishDate: "วันที่สิ้นสุดการทำงานตั้งแต่",
                endDate: "จนถึงวันที่",
                reset: "รีเซ็ต",
                confirm: "กรอง",
              },
              columns: {
                fullName: "ชื่อ นามสกุล",
                email: "อีเมล",
                code: "รหัส",
                department: "ฝ่าย/สังกัด",
                employmentStartDate: "วันที่เริ่มงาน",
                employmentFinishDate: "วันที่สิ้นสุดการทำงาน",
                role: "บทบาท",
                values: {
                  availableStatus: {
                    active: "ใช้งาน",
                    inactive: "ไม่ใช้งาน",
                  },
                },
              },
              syncEmployeeLoading: "กำลังอัพเดตข้อมูลพนักงาน",
              syncEmployeeError:
                "อัพเดทข้อมูลพนักงานไม่สำเร็จ กรุณาลองใหม่อีกครั้ง หรือติดต่อผู้ดูแลระบบ",
            },
            edit: {
              title: "รายละเอียกพนักงาน",
              home: "หน้าแรก",
              rootPath: "พนักงาน",
              cancel: "ย้อนกลับ",
              fullName: "ชื่อ นามสกุล",
              email: "อีเมล",
              status: "สถานะ",
              code: "รหัส",
              phoneNumber: "เบอร์โทรศัพท์",
              department: "ฝ่าย/สังกัด",
              employmentStartDate: "วันที่เริ่มงาน",
              employmentFinishDate: "วันที่สิ้นสุดการทำงาน",
            },
          },
          budget: {
            index: {
              home: "หน้าแรก",
              title: "วงเงินสวัสดิการ",
              filter: {
                title: "ตัวกรอง",
                show: "แสดง",
                hide: "ซ่อน",
                fullName: "ชื่อ นามสกุล",
                code: "รหัส",
                reset: "รีเซ็ต",
                confirm: "กรอง",
                yearlyRequest: "ปีที่ได้รับสวัสดิการ",
              },
              columns: {
                fullName: "ชื่อ นามสกุล",
                code: "รหัส",
                year: "ปี",
                companyBudget: "สิทธิ์บริษัท",
                budget: "คงเหลือ",
                companyDentalBudget: "สิทธิ์ทันตกรรม",
                dentalBudget: "คงเหลือ",
              },
            },
            edit: {
              title: "รายละเอียดวงเงินการเบิกสวัสดิการ",
              home: "หน้าแรก",
              rootPath: "วงเงินการเบิกสวัสดิการ",
              cancel: "ย้อนกลับ",
              code: "รหัส",
              fullName: "ชื่อ นามสกุล",
              year: "ปีที่ได้รับสวัสดิการ",
              companyBudget: "สิทธิ์บริษัทตั้งต้น",
              budget: "สิทธิ์บริษัทคงเหลือ",
              companyDentalBudget: "สิทธิ์ทันตกรรมตั้งต้น",
              dentalBudget: "สิทธิ์ทันตกรรมคงเหลือ",
            },
          },
          request: {
            index: {
              home: "หน้าแรก",
              title: "รายการขอเบิก",
            },
          },
        },
        auth: {
          ResetPassword: {
            forgotPassword: "ลืมรหัสผ่าน",
            inputEmail: "กรอกอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบใหม่",
            send: "ส่ง",
            link1:
              "ลิงก์สำหรับสร้างรหัสผ่านใหม่ได้ถูกส่งไปที่อีเมล \n{{email}}\n",
            link2:
              "เรียบร้อยแล้ว \n กรุณาคลิกลิงก์จากอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบ",
          },
        },
      },
      components: {
        common: {
          languagesDropdown: {
            label: "ภาษา",
            lang: "ไทย",
          },
          PopMenu: {
            logout: "ออกจากระบบ",
            logoutInfo1: "คุณต้องการออกจากระบบใช่หรือไม่ หากต้องการกรุณากดปุ่ม",
            logoutInfo2: "ด้านล่างเพื่อทำการออกจากระบบ",
            confirmLogout: "ยืนยัน",
            cancel: "ยกเลิก",
            profile: "โปรไฟล์",
            avatar: "AD",
          },
          MaterialIcon: {
            notFoundIcon: "ไม่พบไอคอน",
          },
          UploadAvatar: {
            avatar: "AD",
          },
        },
        advance: {
          AddressList: {
            total: "ทั้งหมด {{amount}}",
            addressNo: "ที่อยู่ {{index}}",
          },
          FileUploadList: {
            total: "ทั้งหมด {{amount}}",
          },
        },
        SideBar: {
          appName: "E-Claim",
        },
        Table: {
          noData: "ไม่มีข้อมูล",
          noDataOnFilter:
            "ไม่พบข้อมูลที่ค้นหา กรุณาตรวจสอบค่าในตัวกรองอีกครั้ง",
        },
      },
      routes: {
        menu: {
          adminMenu: "ผู้ดูแลระบบ",
          roleMenu: "บทบาท",
          insuranceMenu: "จัดการชุดแบบประกัน",
          settingMenu: "ตั้งค่าระบบ",
          menu: "เมนู",
          insurance: "จัดการชุดแบบประกัน",
          enrollment: "การลงทะเบียน",
          report: "ออกรายงาน",
          employee: "พนักงาน",
          budget: "วงเงินสวัสดิการ",
          request: "รายการขอเบิก",
        },
      },
      yup: {
        error: {
          required: "ต้องไม่เว้นว่างเอาไว้",
          invalidEmailSyntax: "รูปแบบอีเมลไม่ถูกต้อง",
          typeNumber: "ต้องเป็นตัวเลข",
          GtZero: "ต้องเป็นตัวเลขมากกว่า 0",
          GteZero: "ต้องเป็นตัวเลขมากกว่าหรือเท่ากับ 0",
          passwordNotMatch: "รูปแบบรหัสผ่านไม่ถูกต้อง",
          wrongConfirmPassword: "รหัสผ่านไม่ตรงกัน",
          reportNameWarning: "กรุณากรอกชื่อรายงาน",
          reportDepartmentWarning: "กรุณาเลือกฝ่าย/สังกัด",
          relationWithOwnerWarning: "กรุณาเลือกความสัมพันธ์กับเจ้าของคำร้อง",
          startDateWarning: "กรุณาเลือกวันที่ทำรายการสำเร็จตั้งแต่",
          endDateWarning: "กรุณาเลือกจนถึงวันที่",
          startDateCompareWarning:
            "กรุณาเลือกวันที่สำหรับรายการเปรียบเทียบตั้งแต่",
          endDateCompareWarning: "กรุณาเลือกจนถึงวันที่",
        },
      },
      error: {
        required: "ต้องไม่เว้นว่างเอาไว้",
        saveError: "แบบประกันนี้ถูกเผยแพร่แล้ว",
        accept: "ตกลง",
        saveErrorChildren:
          "การลงทะเบียนนี้ถูกเผยแพร่ไปแล้ว กรุณากดปุ่ม (ตกลง) เพื่อ refresh และลองใหม่อีกครั้ง",
        reportTypeWarning: "กรุณาเลือกประเภทรายงาน",
      },
    },
  },
};

export default th;
